import React, { FC } from "react";
import styled from "styled-components";
import { IconFacebook, IconInstagram } from "./ds/primitive/icons";

const SocialMedia: FC = React.memo((props) => (
    <Container {...props}>
        <a
            href="https://www.facebook.com/OerolTerschelling/"
            target="_blank"
            title="facebook"
        >
            <IconFacebook />
        </a>
        <a
            href="https://www.instagram.com/oerolterschelling/"
            target="_blank"
            title="instagram"
        >
            <IconInstagram />
        </a>
    </Container>
));

const Container = styled.div`
    margin-left: -0.8rem;

    a {
        display: inline-block;
        padding: 0.8rem;
        opacity: 0.5;
        transition: 0.1s;

        &:hover {
            opacity: 1;
        }
    }

    svg {
        width: 1.6rem;
    }
`;

export default SocialMedia;
