import { Auth } from '../contexts/AuthContext';
import { Craft_EventsEvent, Craft_ProgrammingTime, Craft_Programming } from '../graphql';

const userHasAccess = (auth: Auth, event: Craft_EventsEvent) => {
    const programTime: Craft_ProgrammingTime | undefined | null = event.programmingTime?.[0];
    const programming: Craft_Programming | undefined | null = programTime?.programming?.[0];
    const ticketType = programming?.ticketType;

    const free = !!ticketType?.find((t) => t?.slug === 'vrije-toegang');
    const ticket = !!ticketType?.find((t) => t?.slug === 'met-ticket');
    const wristband = !!ticketType?.find((t) => t?.slug === 'met-festivalbandje');
    const wristbandTicket = !!ticketType?.find((t) => t?.slug === 'met-festivalbandje-en-ticket');

    const userHasTicket =
        programTime?.activeTicketsId &&
        auth.userTickets?.tickets &&
        auth.userTickets?.tickets.indexOf(programTime.activeTicketsId) >= 0;

    if (free) {
        return true;
    }
    if (!auth.isLoggedIn) {
        return false;
    }
    if (ticket) {
        if (userHasTicket) {
            return true;
        }
    }
    if (wristband) {
        if (auth.userTickets?.hasFestivalTicket) {
            return true;
        }
    }
    if (wristbandTicket) {
        if (auth.userTickets?.hasFestivalTicket && userHasTicket) {
            return true;
        }
    }

    return false;
};

export default userHasAccess;
