import isBrowser from "./isBrowser";

export const getSearchParam = (key: string) => {
    if (isBrowser) {
        const url = new URL(window.location.href);
        const { searchParams } = url;

        return searchParams.get(key);
    }
    return undefined;
};

export const setSearchParam = (key: string, value: string) => {
    if (isBrowser) {
        const url = new URL(window.location.href);
        const { searchParams } = url;

        searchParams.set(key, value);

        window.history.replaceState(
            {},
            "",
            `${window.location.pathname}?${searchParams}`
        );
    }
};

export const deleteSearchParam = (key: string) => {
    if (isBrowser) {
        const url = new URL(window.location.href);
        const { searchParams } = url;

        searchParams.delete(key);

        window.history.replaceState(
            {},
            "",
            `${window.location.pathname}?${searchParams}`
        );
    }
};

export const clearSearchParam = () => {
    if (isBrowser) {
        window.history.replaceState({}, "", window.location.pathname);
    }
};
