import { DateTime } from "luxon";

const getMinutes = (dateTime: DateTime) => dateTime.minute + dateTime.hour * 60;

export default (
    start: DateTime | string,
    end?: DateTime | string,
    options?: {
        seconds?: boolean;
        now?: DateTime;
        onlyTime?: boolean;
    }
) => {
    const n = options?.now || DateTime.fromObject({ zone: "Europe/Amsterdam" });
    const s =
        typeof start === "string"
            ? DateTime.fromMillis(
                  parseInt(start) * (options?.seconds ? 1000 : 1),
                  { zone: "Europe/Amsterdam" }
              )
            : start;
    const e =
        typeof end === "string"
            ? DateTime.fromMillis(
                  parseInt(end) * (options?.seconds ? 1000 : 1),
                  { zone: "Europe/Amsterdam" }
              )
            : end;

    if (options?.onlyTime) {
        const nTime = getMinutes(n);
        const sTime = getMinutes(s);
        const eTime = e ? getMinutes(e) : undefined;

        return nTime >= sTime && (eTime ? nTime < eTime : true);
    } else {
        return n >= s && (e ? n < e : true);
    }
};
