import { Link } from 'gatsby';
import React, { useCallback, useContext, useMemo } from 'react';

import AuthContext from '../contexts/AuthContext';
import { Craft_EventsEvent, Craft_ProgrammingTime } from '../graphql';
import isBrowser from '../utils/isBrowser';
import webUrlForEvent from '../services/webUrlForEvent';
import userEventTicketOption from '../services/userEventTicketOption';

interface Props {
    event: Craft_EventsEvent;
}

const TicketOptions: React.FC<Props> = ({ event }) => {
    const programTime: Craft_ProgrammingTime | undefined | null = event.programmingTime?.[0];

    const { auth } = useContext(AuthContext);

    const ticketOptionStatus = useMemo(() => userEventTicketOption(event, auth), [event, auth]);

    const renderBuyButton = useCallback(() => <a href={webUrlForEvent(event)}>Kaartje kopen</a>, [event]);

    const renderWristbandButton = useCallback(
        () => <a href={process.env.GATSBY_WRISTBAND_URL}>Toegangsbewijs kopen</a>,
        []
    );

    const renderWatchButton = useCallback(
        () => (
            <Link
                to={`/show/${programTime?.slug || event.id}`}
                state={{
                    prevUrl: isBrowser && window.location.href,
                }}
            >
                {event.artists} {event.blockTitle}
            </Link>
        ),
        [event, programTime]
    );

    switch (ticketOptionStatus) {
        case 'BUY':
            return renderBuyButton();
        case 'BUY_WRISTBAND':
            return renderWristbandButton();
        case 'WATCH':
        case 'WATCH_ON_DEMAND':
            return renderWatchButton();
        case 'LOGIN':
            return <a href={process.env.GATSBY_LOGIN_URL}>Login</a>;
        default:
            return null;
    }
};

export default TicketOptions;
