import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Frame from "../layout/Frame";
import { cover, hideText } from "polished";

interface Props extends React.HTMLProps<HTMLDivElement> {
    icon: ReactNode;
    className?: string;
    link?: ReactNode;
}

const ButtonIcon: FC<Props> = React.memo(({ children, icon, link, ref, as, ...props }) => (
    <Container hasIcon={!!icon} {...props}>
        <StyledFrame variant="dot" tl br background={false}>
            <Icon>{icon}</Icon>
        </StyledFrame>
        {link}
    </Container>
));

const Container = styled.div<{ hasIcon?: boolean }>`
    display: inline-block;
    width: 4.8rem;
    height: 4.8rem;
    cursor: pointer;

    > * {
        width: 100%;
        height: 100%;
    }

    > a {
        ${cover()}
        ${hideText()}
    }

    @media (max-width: 580px) {
        width: 4rem;
        height: 4rem;
    }
`;

const StyledFrame = styled(Frame)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
`;

const Icon = styled.div`
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        background: rgba(255, 255, 255, 0.2);
        filter: blur(1px);
    }

    > svg {
        width: 2rem;
        height: 2rem;
    }
`;

export default ButtonIcon;
