import React, { FC } from "react";
import styled from "styled-components";
import ButtonIcon from "../action/ButtonIcon";
import { IconClose } from "../primitive/icons";
import cssCalcBetweenViewport from "../../../utils/cssCalcBetweenViewport";

export interface SidebarProps {
    collapsable?: boolean;
    expanded?: boolean;
    onCloseClick?: () => void;
}

const Sidebar: FC<SidebarProps> = React.memo(
    ({
        collapsable = false,
        expanded = true,
        onCloseClick,
        children,
        ...props
    }) => {
        return (
            <SB expanded={expanded} {...props}>
                <Container>{children}</Container>
                {collapsable && onCloseClick && (
                    <CloseContainer onClick={() => {
                        onCloseClick();
                    }}>
                        <ButtonIcon
                            icon={<RotatingIconClose rotate={expanded} />}
                        />
                        <CloseLineTop />
                        <CloseArrow expanded={expanded}>&gt;&gt;</CloseArrow>
                        <CloseLineBottom />
                    </CloseContainer>
                )}
            </SB>
        );
    }
);

const SB = styled.aside<{ expanded?: boolean }>`
    transform: translateX(${({ expanded }) =>
        expanded ? 0 : cssCalcBetweenViewport(232, 356)});
    transition: 0.2s ease-out;

    &::before,
    &::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: calc(var(--width) - 0.2rem);
        content: '';
        z-index: -1;
    }

    &::before {
        background-image: radial-gradient(rgba(8,43,7,0.40), rgba(0,0,0,0.96));
        box-shadow: 5px 0 20px 0 rgba(255, 255, 255, 0.5);
        filter: blur(2px);
    }
    &::after {
        background-image: url('${require("../../../images/background-pattern.png")}');
        filter: blur(1px);
    }

    @media (max-width: 580px) {
        transform: none;
        
        &::before,
        &::after {
            width: 100%;
        }
    }
`;

const Container = styled.div`
    position: relative;
    padding: var(--padding);
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
`;

const CloseContainer = styled.div`
    position: absolute;
    height: 100vh;
    padding: 1.6rem 0.8rem;
    box-sizing: border-box;
    top: 0;
    z-index: 1;
    cursor: pointer;
    @media (max-width: 580px) {
        display: none;
    }
`;

const RotatingIconClose = styled(IconClose) <{ rotate?: boolean }>`
    transform: rotate(${({ rotate }) => rotate ? 0 : 45 }deg);
`;

const CloseArrow = styled.div<{ expanded?: boolean }>`
    text-align: center;
    color: #b7b7b7;
    font-size: 1.5rem;
    transform: scale(${({ expanded }) => expanded ? 1 : -1 });
`;

const CloseLineTop = styled.div`
    height: calc(50% - 4.5rem);
    width: 50%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border-right: 1px solid #4d5050;
`;

const CloseLineBottom = styled.div`
    margin-top: 0.5rem;
    height: calc(50% - 4.5rem);
    width: 50%;
    border-right: 1px solid #4d5050;
`;

export default Sidebar;
