import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import useParsedOnlinefestivalQuery from "./useParsedOnlinefestivalQuery";
import {
    setSearchParam,
    clearSearchParam,
    getSearchParam,
} from "../utils/setSearchParam";
import isBrowser from "../utils/isBrowser";

export const EVENT_START_DATE = DateTime.fromObject({ zone: "Europe/Amsterdam" });

const entryIndexForDateTime = (
    sortedData: ReturnType<typeof useParsedOnlinefestivalQuery>["parsedData"],
    now: DateTime
) =>
    sortedData
        ? sortedData.findIndex(
              (day) =>
                  DateTime.fromJSDate(day?.date)
                      .ordinal === now.ordinal
          )
        : -1;

export const useDayNavigation = (
    data: ReturnType<typeof useParsedOnlinefestivalQuery>["parsedData"]
) => {
    const now = DateTime.fromObject({ zone: "Europe/Amsterdam" });
    const seacrhParamDate = getSearchParam("dag");
    const initialSelectedDate =
        (seacrhParamDate && DateTime.fromISO(seacrhParamDate)) || EVENT_START_DATE;
    const [sortedData, setSortedData] = useState<typeof data>(
        data.sort((a, b) => (a?.date > b?.date ? 1 : -1))
    );

    const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
    const [currentIndex, setCurrentIndex] = useState(-1);

    const onPrevDay = () => {
        currentIndex > 0 && setCurrentIndex(currentIndex - 1);
    };

    const onNextDay = () => {
        sortedData &&
            currentIndex < sortedData.length - 1 &&
            setCurrentIndex(currentIndex + 1);
    };

    useEffect(() => {
        if (data.length > 0) {
            const sortedData = data.sort((a, b) =>
                +a?.date > +b?.date ? 1 : -1
            );

            setCurrentIndex(entryIndexForDateTime(sortedData, initialSelectedDate));
            setSortedData(sortedData);
        }
    }, [data]);

    useEffect(() => {
        if (sortedData?.[currentIndex]?.date) {
            setSelectedDate(
                DateTime.fromJSDate(
                    sortedData[currentIndex].date
                )
            );
        }
    }, [currentIndex]);

    useEffect(() => {
        if (isBrowser) {
            if (now.ordinal !== selectedDate.ordinal) {
                setSearchParam("dag", selectedDate.toISODate());
            } else {
                clearSearchParam();
            }
            window.history.pushState({ prevUrl: window.location.href }, '');
        }
    }, [selectedDate]);

    return {
        data: sortedData ? sortedData[currentIndex] : undefined,
        selectedDate: selectedDate,
        dayNumber: currentIndex + 1,
        firstDay: currentIndex === 0,
        lastDay: sortedData && currentIndex === sortedData.length - 1,
        dayNotToday: now.ordinal !== selectedDate.ordinal,
        dayLabel:
            selectedDate.ordinal >= EVENT_START_DATE.ordinal
                ? `Dag ${selectedDate.ordinal - EVENT_START_DATE.ordinal + 1}`
                : '--:--',
        onPrevDay,
        onNextDay,
    };
};

export const getDayLabel = (date: DateTime) => {
    const day = date.ordinal - EVENT_START_DATE.ordinal + 1;

    return day > 0 ? `Dag ${day}` : '--:--';
}