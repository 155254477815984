import { useEffect, useState } from 'react';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    useEffect(() => {
        function checkMobile(mq: MediaQueryList) {
            /**
             * fix for older browsers that don't have a `.matches` value in their mediaquerylist
             */
            const newmq = window.matchMedia("screen and (max-width: 580px)");
            setIsMobile(newmq.matches);
        }

        const mq = window.matchMedia("screen and (max-width: 580px)");
        function mqEvent(this: MediaQueryList, ev: MediaQueryListEvent) {
            checkMobile(this);
        }
        checkMobile(mq);
        mq.addListener(mqEvent);

        return () => {
            mq.removeListener(mqEvent);
        }
    }, []);

    return isMobile;
};
