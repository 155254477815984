import React, { FC, ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Frame from '../layout/Frame';
import { Action } from '../primitive/typography';
import cssGlitch from '../../../utils/cssGlitch';
import { cover, hideText } from 'polished';

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'label'> {
    label: string | ReactNode;
    icon?: ReactNode;
    className?: string;
    link?: ReactNode;
    as?: 'a' | 'span' | 'div';
    type?: 'default' | 'fill';
    small?: boolean;
}

const Button: FC<Props> = React.memo(
    ({ children, label, icon, link, ref, type = 'default', small = false, ...props }) => {
        const isFilled = useMemo(() => type === 'fill', [type]);
        return (
            <StyledA hasIcon={!!icon} type={type} {...props}>
                <Frame variant="bracket" tl={!isFilled} tr={!isFilled} br={!isFilled} bl={!isFilled}>
                    <Label as="span" variant={small ? 'small' : 'regular'} small={small}>
                        {label}
                    </Label>
                    {!!icon && <Icon>{icon}</Icon>}
                </Frame>
                {link}
            </StyledA>
        );
    }
);

const buttonWithIcon = css`
    & > * {
        padding-right: 2.8rem;
    }
`;

const FilledCss = css`
    & > * {
        color: #000;
        background-color: rgba(255, 255, 255, 0.8);
    }

    & > *:before {
        background-image: url('${require('../../../images/texture-black.png')}');
        background-color: rgba(255, 255, 255, 1);
    }
`;

const StyledA = styled.a<{ hasIcon?: boolean; type: Props['type'] }>`
    ${({ hasIcon }) => hasIcon && buttonWithIcon};
    ${({ type }) => type === 'fill' && FilledCss};
    text-decoration: none;
    display: inline-block;
    position: relative;
    cursor: pointer;

    & > * {
        overflow: hidden;
    }

    > a {
        ${cover()}
        ${hideText()}
        text-indent: 200%;
    }
`;

const Icon = styled.div`
    position: absolute;
    right: 1.6rem;
    width: 1.2rem;
    height: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
`;

const Label = styled(Action)<{ small?: boolean }>`
    margin: ${({ small }) => (small ? '0.2rem 0.8rem' : '1.2rem 1.6rem')};
    display: block;

    & > * {
        text-decoration: none;
    }

    ${cssGlitch}
`;

export default Button;
