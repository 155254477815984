import React, { FC } from 'react';

/**
 * List icons
 */

export const IconTime: FC = React.memo((props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <g strokeWidth={1.313} stroke="currentColor" fill="none" fillRule="evenodd">
            <path d="M7 12.998A6 6 0 116.999 1 6 6 0 017 12.998z" />
            <path d="M6.745 3.457v3.64l3.088 1.306" />
        </g>
    </svg>
));

/**
 * Button Icons
 */

export const IconExternal: FC = React.memo((props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path d="M1 10.791l9.546-9.546M10.677 9.197V1H2.859" />
        </g>
    </svg>
));

export const IconClose: FC<{ rotate?: boolean }> = React.memo(({ rotate, ...props }) => (
    <svg viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path d="M2 2l16 15.99M18 2L2 17.99" />
        </g>
    </svg>
));

export const IconPlus: FC = React.memo((props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="currentColor" strokeWidth={1.5} fill="none" fillRule="evenodd">
            <path d="M20 10H0M10 20V0" />
        </g>
    </svg>
));

export const IconPlayLarge: FC = React.memo((props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <g fill="none" fillRule="evenodd">
            <path fill="none" d="M12 6L2 11V1z" stroke="currentColor" strokeWidth={1} />
        </g>
    </svg>
));

export const IconFilter: FC = React.memo((props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path d="M3 0H0l4.667 5.333v5.334L7.334 12V5.333L12 0z" fill="currentColor" fillRule="evenodd" />
    </svg>
));

export const IconList: FC = React.memo((props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <title>{'Icon/List'}</title>
        <g stroke="currentColor" strokeWidth={2} fill="none" fillRule="evenodd">
            <path d="M0 6h12M0 10h12M0 2h12" />
        </g>
    </svg>
));

/**
 * Round icons
 */

export const IconAccount: FC = React.memo((props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icon-/Account" stroke="currentColor">
                <circle id="Oval" cx="8" cy="6" r="2.5"></circle>
                <circle id="Oval" cx="8" cy="8" r="7.5"></circle>
                <path
                    d="M2.61404483,13.1185334 L2.89235253,12.6021766 C3.59025315,11.3073302 4.94252017,10.5 6.41346973,10.5 L9.84801852,10.5 C11.202142,10.5 12.4643266,11.185097 13.2021317,12.3205678 L13.6968262,13.0818952 L13.6968262,13.0818952"
                    id="Path-6"
                ></path>
            </g>
        </g>
    </svg>
));

export const IconPlay: FC = React.memo((props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <path d="M21.2 16l-9.6 4.8v-9.6z" fill="currentColor" fillRule="evenodd" />
    </svg>
));

export const IconArtist: FC = React.memo((props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <g fill="none" fillRule="evenodd">
            <rect fill="currentColor" x={13.5} y={5.5} width={5} height={12} rx={2.5} />
            <path d="M9 15c0 3.866 3.5 7 7 7s7-3.134 7-7M16 22.5v4" stroke="currentColor" strokeWidth={1.5} />
        </g>
    </svg>
));

export const IconGeneric: FC = React.memo((props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <g stroke="currentColor" fill="none" fillRule="evenodd">
            <path d="M23 12H9M23 16H9M17 20H9" />
        </g>
    </svg>
));

export const IconAudio: FC = React.memo((props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <g stroke="currentColor" fill="none" fillRule="evenodd">
            <path d="M8.563 13.298v5.617M22.563 13.298v5.617M19.063 10.089v12.029M15.563 8.75v14.713M12.063 11.96v8.293" />
        </g>
    </svg>
));

/**
 * Blip icons
 */

export const IconHeart: FC = React.memo((props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <g fill="currentColor">
            <path
                d="M13.9270096,2.97836933 C13.2429051,2.27475146 12.325219,1.87496859 11.3610344,1.87496859 C10.3970052,1.87496859 9.47931916,2.27475146 8.77950772,2.97836933 L8.00193945,3.79392639 L7.22437118,2.97836933 C6.54011111,2.27475146 5.62273607,1.87496859 4.64284454,1.87496859 C3.66310852,1.87496859 2.76112933,2.25876015 2.06131789,2.97836933 C0.646299163,4.43357899 0.646299163,6.81628493 2.06131789,8.27149459 L8.00193945,14.3801769 L13.1027873,9.1350256 L13.9270096,8.28748591 C14.6112697,7.58386805 15.0000538,6.64038046 15.0000538,5.63292762 C15.0000538,4.62547477 14.6268211,3.68198719 13.9270096,2.97836933"
                fill="currentColor"
            />
        </g>
    </svg>
));

export const IconTicket: FC = React.memo((props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <g fill="currentColor">
            <path
                d="M7.40784606,2 C7.56832755,2.12762257 7.76229164,2.30507904 7.98973833,2.53236942 L7.98973833,2.53236942 L15,9.54230411 L15,10.8283582 L10.82877,15 L9.54265591,15 L2.53239425,7.98945896 C2.30509327,7.76202288 2.12762852,7.57374717 2,7.42463184 L2,7.42463184 L2,2 L2,2 Z M5.25,4.25 C4.69771525,4.25 4.25,4.69771525 4.25,5.25 C4.25,5.80228475 4.69771525,6.25 5.25,6.25 C5.80228475,6.25 6.25,5.80228475 6.25,5.25 C6.25,4.69771525 5.80228475,4.25 5.25,4.25 Z"
                fill="currentColor"
            />
        </g>
    </svg>
));

export const IconEye: FC = React.memo((props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <g fill="currentColor">
            <path
                d="M0,8 C5.60406091,1.14208663 10.8832487,1.52736266 16,8 C10.8832487,14.4726373 5.60406091,14.8579134 0,8 Z M7.95939086,4.84073654 C6.17258883,4.84073654 4.62944162,6.22773025 4.62944162,8 C4.62944162,9.69521454 6.09137056,11.1592635 7.95939086,11.1592635 C9.74619289,11.1592635 11.2893401,9.77226975 11.2893401,8 C11.2893401,6.30478546 9.82741117,4.84073654 7.95939086,4.84073654 Z M8.04060914,6.38184067 C8.93401015,6.38184067 9.74619289,7.07533752 9.74619289,8 C9.74619289,8.84760727 9.01522843,9.61815933 8.04060914,9.61815933 C7.14720812,9.61815933 6.33502538,8.92466248 6.33502538,8 C6.33502538,7.15239273 7.06598985,6.38184067 8.04060914,6.38184067 Z"
                fill="currentColor"
            />
        </g>
    </svg>
));

/**
 * Social Media
 */

export const IconFacebook: FC = React.memo((props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M1.918 0A1.932 1.932 0 000 1.932v31.15A1.932 1.932 0 001.932 35h16.765V21.465h-4.55v-5.299h4.55v-3.895c0-4.519 2.762-7 6.794-7a36.6 36.6 0 014.073.21v4.739h-2.8c-2.187 0-2.61 1.05-2.61 2.572v3.374H29.4l-.7 5.3h-4.55V35h8.918A1.932 1.932 0 0035 33.068V1.918A1.932 1.932 0 0033.068 0H1.918z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
));

export const IconInstagram: FC = React.memo((props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M19.167.012c3.257.005 3.92.03 5.54.104 1.863.085 3.134.381 4.247.814a8.575 8.575 0 013.099 2.017 8.576 8.576 0 012.018 3.099c.432 1.113.728 2.384.813 4.247.078 1.718.101 2.36.105 6.162v2.1c-.004 3.804-.027 4.445-.105 6.163-.085 1.863-.381 3.134-.813 4.247a8.576 8.576 0 01-2.018 3.099 8.576 8.576 0 01-3.099 2.018c-1.113.432-2.384.728-4.247.813-1.718.078-2.36.101-6.162.105h-2.1c-3.804-.004-4.445-.027-6.163-.105-1.863-.085-3.134-.381-4.247-.813a8.576 8.576 0 01-3.099-2.018 8.575 8.575 0 01-2.018-3.099C.486 27.852.19 26.581.105 24.718c-.074-1.62-.099-2.282-.104-5.54v-3.344c.005-3.258.03-3.921.104-5.541C.19 8.43.486 7.159.918 6.046a8.575 8.575 0 012.018-3.099A8.575 8.575 0 016.035.93C7.148.497 8.419.2 10.282.116c1.62-.074 2.282-.099 5.54-.104zm-.95 3.151h-1.445c-4.012.002-4.602.023-6.347.102-1.705.078-2.632.363-3.248.603a5.42 5.42 0 00-2.012 1.308 5.42 5.42 0 00-1.308 2.012c-.24.616-.525 1.543-.603 3.248-.08 1.745-.1 2.335-.102 6.347v1.445c.002 4.012.023 4.602.102 6.347.078 1.705.363 2.632.603 3.248a5.42 5.42 0 001.308 2.012 5.42 5.42 0 002.012 1.308c.616.24 1.543.525 3.248.603 1.695.077 2.3.099 6.012.101h2.115c3.712-.002 4.317-.024 6.012-.101 1.705-.078 2.632-.363 3.248-.603a5.42 5.42 0 002.012-1.308 5.42 5.42 0 001.308-2.012c.24-.616.525-1.543.603-3.248.077-1.695.098-2.3.101-6.012v-2.115c-.003-3.712-.024-4.317-.101-6.012-.078-1.705-.363-2.632-.603-3.248a5.42 5.42 0 00-1.308-2.012 5.42 5.42 0 00-2.012-1.308c-.616-.24-1.543-.525-3.248-.603-1.745-.08-2.335-.1-6.347-.102zM17.984 8a8.984 8.984 0 110 17.967 8.984 8.984 0 010-17.967zm0 3.152a5.831 5.831 0 100 11.663 5.831 5.831 0 000-11.663zM27.099 6a2.1 2.1 0 110 4.199A2.1 2.1 0 0127.1 6z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
));
