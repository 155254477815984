import { Craft_EventsEvent, Craft_ProgrammingTime, Craft_Programming } from '../graphql';
import { Auth } from '../contexts/AuthContext';

type TicketOptionStatus = 'BUY' | 'BUY_WRISTBAND' | 'WATCH' | 'WATCH_ON_DEMAND' | 'LOGIN' | null;

const userEventTicketOption = (event: Craft_EventsEvent, auth: Auth): TicketOptionStatus => {
    const programTime: Craft_ProgrammingTime | undefined | null = event.programmingTime?.[0];
    const programming: Craft_Programming | undefined | null = programTime?.programming?.[0];
    const ticketType = programming?.ticketType;

    const { isLoggedIn, userTickets } = auth;

    const free = !!ticketType?.find((t) => t?.slug === 'vrije-toegang');
    const ticket = !!ticketType?.find((t) => t?.slug === 'met-ticket');
    const wristband = !!ticketType?.find((t) => t?.slug === 'met-festivalbandje');
    const wristbandTicket = !!ticketType?.find((t) => t?.slug === 'met-festivalbandje-en-ticket');

    const userHasTicket =
        programTime?.activeTicketsId &&
        userTickets?.tickets &&
        userTickets?.tickets.indexOf(programTime.activeTicketsId) >= 0;

    if (free) {
        return programTime?.isOnDemand ? 'WATCH_ON_DEMAND' : 'WATCH';
    }
    if (!isLoggedIn && (ticket || wristband || wristbandTicket)) {
        return 'LOGIN';
    }
    if (ticket) {
        if (userHasTicket) {
            return programTime?.isOnDemand ? 'WATCH_ON_DEMAND' : 'WATCH';
        }
        return 'BUY';
    }
    if (wristband) {
        if (userTickets?.hasFestivalTicket) {
            return programTime?.isOnDemand ? 'WATCH_ON_DEMAND' : 'WATCH';
        }
        return 'BUY_WRISTBAND';
    }
    if (wristbandTicket) {
        if (userTickets?.hasFestivalTicket && userHasTicket) {
            return programTime?.isOnDemand ? 'WATCH_ON_DEMAND' : 'WATCH';
        }
        if (!userTickets?.hasFestivalTicket) {
            return 'BUY_WRISTBAND';
        }
        if (!userHasTicket) {
            return 'BUY';
        }
    }

    return null;
};

export default userEventTicketOption;
