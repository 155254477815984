import { Craft_EventsEvent, Craft_ProgrammingTime } from '../graphql';

const webUrlForEvent = (event: Craft_EventsEvent) => {
    const time: Craft_ProgrammingTime | undefined | null = event.programmingTime?.[0];
    if (time) {
        return `${process.env.GATSBY_PROGRAMMING_URL}${time.programming?.[0]?.slug}`;
    }
    return '';
};

export default webUrlForEvent;
