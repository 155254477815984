import React, { FC } from "react";
import Frame, { FrameProps } from "../layout/Frame";
import styled, { css } from "styled-components";
import { IconClose } from "../primitive/icons";
import ButtonIcon from "../action/ButtonIcon";

export interface BlockModalProps extends FrameProps {
    open: boolean;
    canClose?: boolean;
    onClickClose?: (e: any) => void;
}

const BlockModal: FC<BlockModalProps> = React.memo(({
    onClickClose,
    canClose = true,
    children,
    open = false,
    ...props
}) => (
    <StyledFrame open={open} tr {...props}>
        {canClose && (
            <CloseButton icon={<IconClose />} onClick={onClickClose} />
        )}
        {children}
    </StyledFrame>
));

const modalClose = css`
    pointer-events: none;
    transform: scale(1, 0) translate3d(0, 0, 0);
`;

const modalOpen = css`
    @supports (-webkit-touch-callout: none) {
        transform: scale(1, 1) translate3d(0, 0, 0);
    }

    @supports not (-webkit-touch-callout: none) {
        animation: turn-on 0.25s cubic-bezier(0.23, 1, 0.32, 1);
    }


    @keyframes turn-on {
        0% {
            transform: scale(0, 0.0001) translate3d(0, 0, 0);
            filter: brightness(50);
        }
        40% {
            transform: scale(1.3, 0.001) translate3d(0, 0, 0);
            filter: brightness(10);
        }
        100% {
            transform: scale(1, 1) translate3d(0, 0, 0);
            filter: brightness(1);
            opacity: 1;
        }
    }
`;

const StyledFrame = styled(Frame)<{ open: boolean }>`
    position: relative;
    width: 32.5rem;
    transition: 0.05s transform cubic-bezier(0.5, 0, 0.75, 0);
    will-change: transform;
    ${({ open }) => (open ? modalOpen : modalClose)}
`;

const CloseButton = styled(ButtonIcon)`
    position: absolute;
    bottom: 100%;
    left: 100%;
    z-index: 1;

    @media (max-width: 580px) {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
    }
`;

export default BlockModal;
